import React from 'react';
import {
	Flex,
	Text,
	Spacer,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	IconButton,
	Select,
} from '@chakra-ui/react';
import Locale from '../../utils/translations';
import useTranslation from '../hooks/useTranslations';
import { LikeButton, DislikeButton } from '../icons/icons';
import { BonfireAPI, ApiType } from '../../models/BonfireApi';
import { LanguageOptionsModel } from '../../utils/interfaces';
import TranslationModel from '../../models/translations/translations';

const TranslationListItem = ({
	translation,
	onLikeButtonClicked,
	onDislikeButtonClicked,
	getLanguageFromCode,
}: {
	translation: TranslationModel;
	onLikeButtonClicked: (action: string, id: string) => void;
	onDislikeButtonClicked: (action: string, id: string) => void;
	getLanguageFromCode: (code: string) => string;
}) => {
	return (
		<Tr bg="white" borderRadius="4px" fontWeight="600" fontSize="14px">
			<Td
				border="1px solid #F5F1F1"
				borderRight="none"
				p="8px 16px"
				borderStartRadius="4px"
			/>

			<Td borderTop="1px solid #F5F1F1" display="grid" gridTemplateColumns="auto 1fr">
				<Text variant="beTextDescription">
					{getLanguageFromCode(translation.getInputAttribute('language'))}:
				</Text>
				<Text variant="beTextDescription" ml={'20px'}>
					{translation.input.text}
				</Text>
				<Text variant="beTextDescription" color="#1462F0">
					{getLanguageFromCode(translation.getOutputAttribute('language'))}:
				</Text>
				<Text variant="beTextDescription" color="#1462F0" ml={'20px'}>
					{translation.output.text}
				</Text>
			</Td>
			<Td borderTop="1px solid #F5F1F1" borderBottom="1px solid #F5F1F1">
				<IconButton
					onClick={() => onLikeButtonClicked('yes', translation._id)}
					colorScheme="#fff"
					size={'lg'}
					aria-label="yes-answer"
					icon={<LikeButton fill={translation.answer === 'yes' ? 'green' : '#1462F0'} />}
				/>
			</Td>
			<Td borderTop="1px solid #F5F1F1" borderBottom="1px solid #F5F1F1">
				<IconButton
					onClick={() => onDislikeButtonClicked('no', translation._id)}
					colorScheme="#fff"
					size={'lg'}
					aria-label="yes-answer"
					icon={<DislikeButton fill={translation.answer === 'no' ? 'red' : '#1462F0'} />}
				/>
			</Td>
		</Tr>
	);
};
const Translation: React.FC = () => {
	const [languagesList, setLanguagesList] = React.useState<
		{ label: string; value: string }[] | []
	>([]);
	const [inputFilter, setInputFilter] = React.useState<string | undefined>('');
	const [outputFilter, setOutputFilter] = React.useState<string | undefined>('');

	const {
		translations,
		translationsLoading,
		moveNext,
		movePrev,
		offset,
		pageSize,
		mutateTranslations,
	} = useTranslation('translation', inputFilter, outputFilter);

	const onLikeButtonClicked = async (action: string, id: string) => {
		const api = new BonfireAPI(ApiType.QaApi);
		await api.patch('/qa/updateAnswer', { action, id }).then(() => {
			mutateTranslations();
		});
	};
	const loadLanguagesList = async () => {
		try {
			const api = new BonfireAPI(ApiType.BonfireCore);
			const data = await api.get('languages/get');

			const languagesOptions = data.map((language: LanguageOptionsModel) => {
				return { label: language.name, value: language.code };
			});

			setLanguagesList(languagesOptions);
		} catch (error) {
			console.error(error);
		}
	};
	const onInputFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setOutputFilter('');
		setInputFilter(e.target.value);
	};

	const onOutputFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setInputFilter('');
		setOutputFilter(e.target.value);
	};

	const getLanguageFromCode = (code: string) => {
		if (languagesList.length === 0) {
			return code;
		}
		const lang = languagesList.find(
			(language: { value: string; label: string }) => language.value === code,
		);
		return lang?.label || code;
	};

	React.useEffect(() => {
		loadLanguagesList();
	}, []);
	if (translationsLoading) {
		return <div>loading ...</div>;
	}

	return (
		<Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'}>
			<Flex alignItems="center" justifyContent="space-between" w={'60%'}>
				<Text fontSize="18px" fontWeight="700">
					{Locale.get('Translations')}
				</Text>
				<Spacer />
				<Select
					bgColor={'#fff'}
					borderRadius={'4px'}
					mr={2}
					size={'sm'}
					width={'20%'}
					placeholder="Filter by input language"
					value={inputFilter}
					onChange={onInputFilterChange}
				>
					{languagesList.map(
						(option: { value: string; label: string }, index: number) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						),
					)}
				</Select>
				<Select
					bgColor={'#fff'}
					borderRadius={'4px'}
					mr={2}
					size={'sm'}
					width={'20%'}
					placeholder="Filter by output language"
					value={outputFilter}
					onChange={onOutputFilterChange}
				>
					{languagesList.map(
						(option: { value: string; label: string }, index: number) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						),
					)}
				</Select>
				<Button
					isDisabled={offset === 0}
					variant="beSecondaryButton"
					size="sm"
					mr={5}
					onClick={movePrev}
				>
					previous
				</Button>
				<Text variant="beTextDescription">
					{offset + 1} .. {offset + pageSize}
				</Text>
				<Button
					isDisabled={translations?.length !== 20}
					variant="bePrimaryButton"
					size="sm"
					ml={5}
					onClick={moveNext}
				>
					next
				</Button>
			</Flex>
			<Table
				mt="23px"
				variant="unstyled"
				style={{ borderCollapse: 'separate', borderSpacing: '0 0.5em' }}
				w={'60%'}
			>
				<Tbody>
					{translations?.map((translation: TranslationModel, index: number) => (
						<TranslationListItem
							getLanguageFromCode={getLanguageFromCode}
							translation={translation}
							key={index}
							onLikeButtonClicked={() => onLikeButtonClicked('yes', translation._id)}
							onDislikeButtonClicked={() =>
								onLikeButtonClicked('no', translation._id)
							}
						/>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default Translation;
